import Vue from "vue";

/*TITLE*/
document.title = "Villas de Cuarte | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Villas de Cuarte";
Vue.prototype.$subtitle = "Cuarte de Huerva | Zaragoza";

/*INTRO*/
Vue.prototype.$promoter = "Villas de Cuarte";
Vue.prototype.$introSubtitle = "Cuarte de Huerva | Zaragoza";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091424.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091424.jpeg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091424.jpeg";
Vue.prototype.$image_bath2 = "0-bathroom2--20221124091424.jpeg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091424.jpeg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091424.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091424.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "0-bathroom2--20221124091424.jpeg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/villasdecuarte/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=cmo%20del%20plano%20cuarte%20de%20huerva%20zaragoza%204250411",
    text: "Cmo. del Plano, (Cuarte de Huerva), Zaragoza, 4250411",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=paseo%20de%20la%20independencia%2022%20planta%208%20oficina%206%2050004%20zaragoza",
    text: "Paseo de la Independencia 22, Planta 8, Oficina 6. 50004 Zaragoza",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:976232422|609752562",
    text: "976232422|609752562",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@segiaresidencial.com",
    text: "info@segiaresidencial.com",
  },
];
